import {PewComponent} from "../../../../assets/raw/js/components/pew-component";
import {RecipeFilterComponent} from "../../RecipeFilter/RecipeFilterComponent";

export class RecipeLink extends PewComponent {

  init() {
    //Find recipe link via the .recipe-link class
    this.element.on('click', (e) => {
      let $thisLink = $(e.currentTarget);
      const isMobile = window.innerWidth < 768;
      //Check if we are on mobile
      if (isMobile) {
        e.preventDefault();
        //If we are, we need to add a ?columns=2 to the URL to force the correct layout, and pagination computation
        //This cannot be done server-side because the markup is cached by wp-rocket so the server doesn't know we are on mobile
        let href = $thisLink.attr('href');
        if (href.indexOf('?') === -1) {
          href += '?columns=2';
        } else {
          href += '&columns=2';
        }

        //Redirect to the new URL
        window.location.href = href;
      }
    });
  }
}

window.pew.addRegistryEntry({
  key: "recipe-mobile-link-enhancer",
  domSelector: ".recipe-link",
  classDef: RecipeLink
});
